import React, { useState, useContext, useEffect } from "react";
import { Modal, Divider, Button, message } from "antd";
import Cancel from "../assets/cancel.svg";
import AccountIcon from "../assets/account-icon.svg";
import ChannelIcon from "../assets/channel-icon.svg";
import TabButton from "./TabButton";
import AccountSettings from "./AccountSettings";
import ChannelSettings from "./ChannelSettings";
import { AuthContext } from "../context/authContext"; // Adjust the import path
import { updateUser } from "../services/userService"; // Adjust the import path
import {
  updateDiscordChannels,
  updateTelegramChannels,
  fetchChannelsByType,
} from "../services/channel"; // Import channel APIs
import TutorialsPlayModal from "./TutorialsPlayModal";

const SettingsModal = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState("account");
  const [step, setStep] = useState("discord");
  const [discordChannels, setDiscordChannels] = useState([]);
  const [telegramChannels, setTelegramChannels] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [sport, setSport] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for Save button
  const [channelLoading, setChannelLoading] = useState(false); // Loading state for channel operations
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false);

  // Fetch name, email, timezone, and userId from AuthContext
  const { name, email, timezone, updateUserDetails } = useContext(AuthContext);

  // Local state for full name and timezone (for editing)
  const [fullName, setFullName] = useState(name || "");
  const [updatedTimezone, setUpdatedTimezone] = useState(timezone || "America/New_York");

  // Fetch channels when the modal opens and the "channel" tab is active
  useEffect(() => {
    fetchChannels(); // Fetch channels for the active step (Discord or Telegram)
  }, []);

  // Fetch channels from the backend
  const fetchChannels = async () => {
    setChannelLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const discordChannels = await fetchChannelsByType(userId, 'discord');
      const telegramChannels = await fetchChannelsByType(userId, 'telegram')      
      setDiscordChannels(discordChannels);
      setTelegramChannels(telegramChannels);
    } catch (error) {
      message.error(error.message);
    } finally {
      setChannelLoading(false);
    }
  };

  // Add a new channel
  const addChannel = () => {
    if (channelId && channelName && sport) {
      if (step === "discord") {
        setDiscordChannels([
          ...discordChannels,
          { channelID: channelId, channelName, sports: sport },
        ]);
      } else {
        setTelegramChannels([
          ...telegramChannels,
          { channelID: channelId, channelName, sports: sport },
        ]);
      }
      setChannelId("");
      setChannelName("");
      setSport("");
    } else {
      message.warning("Please fill all fields to add a channel.");
    }
  };

  // Remove a channel
  const removeChannel = (index) => {
    if (step === "discord") {
      setDiscordChannels(discordChannels.filter((_, i) => i !== index));
    } else {
      setTelegramChannels(telegramChannels.filter((_, i) => i !== index));
    }
  };

  // Handle the "Save" button click
  const handleSave = async () => {
    setLoading(true); // Start loading
    try {
      const userId = localStorage.getItem("userId");

      // Update user details only if the active tab is "account"
      if (activeTab === "account") {
        const userPayload = {
          name: fullName,
          timezone: updatedTimezone,
        };
        await updateUser(userId, userPayload);
        message.success("User settings updated");
        updateUserDetails(fullName, updatedTimezone);
      }
      else if (activeTab === "channel") {
          if (discordChannels.length === 0) {
            await updateDiscordChannels(userId, []); // Delete all Discord channels
          } else {
            await updateDiscordChannels(userId, discordChannels);
          }
          if (telegramChannels.length === 0) {
            await updateTelegramChannels(userId, []); // Delete all Telegram channels
          } else {
            await updateTelegramChannels(userId, telegramChannels);
          }
          message.success("Channels updated");          
      }
    } catch (error) {
      console.error("Error saving settings:", error);
      message.error(error.message || "Failed to save settings");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <Modal
        open={true}
        footer={null}
        onCancel={onClose}
        closable={false}
        centered
        width={700}
        getContainer={false}
      >
        <div className="flex justify-between items-center mb-3">
          <p className="text-lg font-semibold">Settings</p>
          <img
            src={Cancel}
            alt="cancel"
            className="h-4 cursor-pointer"
            onClick={onClose}
          />
        </div>
        <Divider className="w-[calc(100%+48px)] -ml-6 m-0" />
        <div className="flex min-h-[310px]">
          <div className="w-[30%] pt-4">
            <TabButton
              icon={AccountIcon}
              label="Account"
              active={activeTab === "account"}
              onClick={() => setActiveTab("account")}
            />
            <TabButton
              icon={ChannelIcon}
              label="Channel"
              active={activeTab === "channel"}
              onClick={() => setActiveTab("channel")}
            />
          </div>
          <div className="w-[70%] p-4">
            {activeTab === "account" && (
              <AccountSettings
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                timezone={updatedTimezone}
                setTimezone={setUpdatedTimezone}
              />
            )}
            {activeTab === "channel" && (
              <ChannelSettings
                step={step}
                setStep={setStep}
                discordChannels={discordChannels}
                setDiscordChannels={setDiscordChannels}
                telegramChannels={telegramChannels}
                setTelegramChannels={setTelegramChannels}
                channelId={channelId}
                setChannelId={setChannelId}
                channelName={channelName}
                setChannelName={setChannelName}
                sport={sport}
                setSport={setSport}
                addChannel={addChannel}
                removeChannel={removeChannel}
                loading={channelLoading}
                setIsTutorialModalVisible={setIsTutorialModalVisible}
              />
            )}
          </div>
        </div>
        <Divider className="w-[calc(100%+48px)] -ml-6 m-0" />
        <div className="flex justify-end gap-2 mt-5">
          <Button onClick={onClose} className="bg-[#F2F4F7] font-medium text-small">
            Cancel
          </Button>
          <Button
            type="primary"
            className="bg-[#E61F26] text-white hover:bg-[#C91A20] font-medium text-small"
            onClick={handleSave}
            loading={loading} // Add loading state to the button
          >
            Save
          </Button>
        </div>
      </Modal>
      {isTutorialModalVisible && (
        <TutorialsPlayModal
          onClose={() => setIsTutorialModalVisible(false)}
          discord={step === "discord"}
        />
      )}      
    </div>
  );
};

export default SettingsModal;