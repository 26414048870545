import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";

const faqs = [
  {
    question: "What sports can I ask about?",
    answer:
      "You can ask about a wide range of sports, including but not limited to soccer, basketball, football, baseball, hockey, tennis, and golf. If you’re interested in a less common sport, feel free to ask!",
  },
  {
    question: "How current is the sports information you provide?",
    answer:
      "Our information is updated regularly to ensure accuracy. For real-time scores and live updates, please specify the sport or event you’re interested in.",
  },
  {
    question: "Can I get personalized sports news and updates?",
    answer:
      "Coming soon. You just need let us know your favorite teams, players, or sports, and we can tailor the news and updates to your preferences.",
  },
  {
    question: "Can you help with game statistics and player profiles?",
    answer:
      "Absolutely! We can provide detailed game statistics, player profiles, and historical performance data.",
  },
  {
    question: "How do I get information on upcoming games or events?",
    answer:
      "Simply ask about upcoming games or events for a specific team or sport, and we’ll provide you with the schedule and relevant details.",
  },
  {
    question: "Can I ask about sports rules and regulations?",
    answer:
      "Yes, you can! We can explain the rules and regulations for most sports, whether you need a basic overview or more detailed information.",
  },
  {
    question: "Is there a way to get injury updates on players?",
    answer:
      "Coming soon. We will just need to know which player or team you’re interested in.",
  },
  {
    question: "How do I provide feedback or report issues with the tool?",
    answer:
      "You can provide feedback or report issues by using the feedback feature within application or by contacting our support team directly: support@protvguide.com",
  },
  {
    question: "Can you help with sports trivia and historical facts?",
    answer:
      "Certainly! We can share interesting sports trivia and historical facts about teams, players, and significant events in sports history.",
  },
  {
    question:
      "Is there a way to follow specific sports events through the app?",
    answer:
      "Coming soon. You will be able to follow specific events by setting up notifications for your favorite sports or teams to stay updated on scores and news.",
  },
  {
    question:
      "Is there a cost associated with using the sports chatbot application?",
    answer:
      "The app is free to use with a limited number of queries. Once you reach the limit, you can choose to pay per use or subscribe for unlimited access.",
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="w-full flex flex-col px-10 pt-[100px] pb-6 md:py-10 gap-[20px] min-h-full overflow-y-auto">
      <span className="text-[#101828] text-center font-Popins text-[26px] md:text-[36px] not-italic font-[600] leading-[32px] md:leading-[44px] tracking-[-0.72px]">
        Frequently asked questions
      </span>
      <div>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleExpansion(`panel${index}`)}
            TransitionComponent={Fade} // Pass Fade directly without spreading props
            TransitionProps={{ timeout: 300 }} // Pass timeout through TransitionProps
            sx={{
              "& .MuiAccordionDetails-root": {
                display: expanded === `panel${index}` ? "block" : "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                textAlign: "left",
              }}
            >
              <span className="text-[16px] md:text-[18px]  font-Popins text-[#101828] not-italic font-[500] leading-[28px] ">
                {faq.question}
              </span>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
              }}
            >
              <span className="text-[14px] md:text-[16px]  font-Popins  text-[#667085] not-italic font-[400] leading-[24px] ">
                {faq.answer}
              </span>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
