import React from "react";
import { Modal } from "antd";
import Cancel from "../assets/cancel.svg";

const TutorialsPlayModal = ({ onClose, discord = true }) => {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <Modal
        open={true}
        footer={null}
        onCancel={onClose}
        closable={false}
        width={600}
        centered
        title={
          <div className="flex justify-between items-center">
            <p className="text-lg font-semibold">Tutorial</p>
            <img
              src={Cancel}
              alt="cancel"
              className="h-4 cursor-pointer"
              onClick={onClose}
            />
          </div>
        }
        style={{ width: 500 }}
      >
        <div className="flex justify-center items-center">
          <video
            width="100%"
            height="350"
            controls
            style={{ borderRadius: 5, height:'350px'}}
          >
            <source src={discord? "/Discord.mp4":"/Telegram.mp4"}type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default TutorialsPlayModal;