import axios from "axios";
import React from "react";

function FeedbackModal({ setIsModalOpen, setFeedback, feedback, selectedFeedback }) {

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newFeedback = {
      type: selectedFeedback.type,
      text: feedback
    }
    
    try {
      const response = await axios.post(`https://api.protvguide.com/api/chat/messages/${selectedFeedback.id}/feedback`, newFeedback);
      console.log("Feedback Posted: ", response);
      setFeedback("");
      setIsModalOpen(false);
    } catch (error) {
     console.log("Error Posting feedback: ", error); 
    }

    
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" fixed inset-0 bg-white bg-opacity-50 flex justify-center font-Popins items-center">
      <div className="bg-[#ACACBE] flex flex-col justify-evenly p-4 rounded-md shadow-md w-[300px] md:w-[400px] h-[250px] md:h-[300px]">
        <h2 className="text-[24px] md:text-[32px] text-white font-[700] ">Provide Feedback</h2>
        <form 
        onSubmit={handleSubmit}
        className="flex flex-col gap-4"
        >
          <textarea
            className="w-full flex h-[100px] md:h-[150px] p-2 border rounded-md"
            placeholder="Enter your feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-2 md:px-4 py-1 md:py-2 text-[14px] md:text-[18px]  leading-[20px] md:leading-[24px] bg-white  rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-2 md:px-4 py-1 md:py-2 bg-[#E61F26] text-white text-[14px] md:text-[18px] leading-[20px] md:leading-[24px] rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackModal;
