import axios from "axios";

// const API_BASE_URL = "http://localhost:5005/api/channel"; // Replace with your backend URL
const API_BASE_URL = 'https://api.protvguide.com/api/channel';

// Add/Update Discord Channels
export const updateDiscordChannels = async (user_id, channels) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/discord-channels`, {
      user_id,
      channels,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to update Discord channels");
  }
};

// Add/Update Telegram Channels
export const updateTelegramChannels = async (user_id, channels) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/telegram-channels`, {
      user_id,
      channels,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to update Telegram channels");
  }
};

// Fetch Channels by Type
export const fetchChannelsByType = async (user_id, type) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/channels`, {
      params: { user_id, type },
    });
    return response.data.channels;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch channels");
  }
};