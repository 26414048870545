import React, { useState } from "react";
import { Button, Typography, Card, message } from "antd";
import { PlayCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import TutorialsPlayModal from "./TutorialsPlayModal";
import Logo from "../assets/Logo.svg";
import Discord from "../assets/discord.svg";
import Telegram from "../assets/telegram.svg";
import { updateDiscordChannels, updateTelegramChannels } from "../services/channel"; // Import from channel.js
import { completeOnboarding } from "../services/userService"; // Import from user.js
import AddChannelForm from "./AddChannelForm";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const [step, setStep] = useState("discord");
  const [discordChannels, setDiscordChannels] = useState([]);
  const [telegramChannels, setTelegramChannels] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [sport, setSport] = useState("");
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  // Add a new channel
  const addChannel = () => {
    if (channelId && channelName && sport) {
      if (step === "discord") {
        setDiscordChannels([...discordChannels, { channelID: channelId, channelName, sports: sport }]);
      } else {
        setTelegramChannels([...telegramChannels, { channelID: channelId, channelName, sports: sport }]);
      }
      setChannelId("");
      setChannelName("");
      setSport("");
    } else {
      message.warning("Please fill all fields to add a channel.");
    }
  };

  // Remove a channel
  const removeChannel = (index) => {
    if (step === "discord") {
      setDiscordChannels(discordChannels.filter((_, i) => i !== index));
    } else {
      setTelegramChannels(telegramChannels.filter((_, i) => i !== index));
    }
  };

  // Handle the "Finish" button click
  const handleFinish = async () => {
    setLoading(true); // Start loading
    try {
      const userId = localStorage.getItem("userId");

      // Update Discord channels
      if (discordChannels.length > 0) {
        await updateDiscordChannels(userId, discordChannels);
        message.success("Added Discord channels")
      }

      // Update Telegram channels
      if (telegramChannels.length > 0) {
        await updateTelegramChannels(userId, telegramChannels);
        message.success("Added Telegram channels")        
      }

      // Mark onboarding as complete
      const response = await completeOnboarding(userId);

      if(response.message === "Onboarding completed successfully"){        
        message.success("Onboarding completed successfully!");
        localStorage.setItem('onboarding', true);
        navigate('/chat');
      }
      // Redirect or perform any other action after onboarding
    } catch (error) {
      console.error("Error completing onboarding:", error);
      message.error(error.message || "Failed to complete onboarding");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100 p-3">
      <Card className="w-[500px] border shadow-md rounded-lg bg-white">
        <div className="flex justify-between items-center mb-2">
          <img src={Logo} alt="Logo" className="h-8" />
          <Button
            icon={<PlayCircleOutlined style={{ color: "black" }} />}
            type="text"
            style={{ backgroundColor: "#F2F4F7", color: "black" }}
            onClick={() => setIsTutorialModalVisible(true)}
          >
            Tutorial
          </Button>
        </div>

        <Typography.Title level={4} className="text-left font-[600]">
          {step === "discord" ? "Add Discord Channels" : "Add Telegram Channels"}
        </Typography.Title>

        <div className="mt-4">
          <AddChannelForm 
            step={step} 
            channelId={channelId} 
            setChannelId={setChannelId} 
            channelName={channelName} 
            setChannelName={setChannelName} 
            sport={sport} 
            setSport={setSport}
            addChannel={addChannel}
          />
        </div>
        <Typography.Title level={5} className="mt-6 text-left">
          Added Channels
        </Typography.Title>
        <div className="flex flex-col gap-2">
          {(step === "discord" ? discordChannels : telegramChannels).map((channel, index) => (
            <div
              key={index}
              className="bg-[#F9FAFB] border border-[#D0D5DD] p-2 rounded-md flex items-center justify-between"
            >
              <div className="flex items-center gap-2">
                <img
                  src={step === "discord" ? Discord : Telegram}
                  alt="Platform"
                  className="h-3"
                />
                <div className="flex flex-col items-start">
                  <Typography.Text strong>{channel.channelName}</Typography.Text>
                  <Typography.Text style={{ fontSize: "12px", color: "gray" }}>
                    #ID {channel.channelID}
                  </Typography.Text>
                </div>
              </div>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => removeChannel(index)}
              />
            </div>
          ))}
        </div>

        <div className="flex justify-between mt-6">
          <Button disabled={loading} onClick={() => setStep(step === "discord" ? "telegram" : "discord")}>
            {step === "discord" ? "Skip" : "Back"}
          </Button>
          <Button
            type="primary"
            className="bg-[#E61F26] text-white hover:bg-[#C91A20]"
            onClick={() => {
              if (step === "discord") {
                setStep("telegram");
              } else {
                handleFinish();
              }
            }}
            loading={loading} // Add loading state to the button
          >
            {step === "discord" ? "Continue" : "Finish"}
          </Button>
        </div>
      </Card>
      {isTutorialModalVisible && (
        <TutorialsPlayModal
          onClose={() => setIsTutorialModalVisible(false)}
          discord={step === "discord"}
        />
      )}
    </div>
  );
};

export default Onboarding;