import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [creditBalance, setCreditBalance] = useState(0);
    const [role, setRole] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedCreditBalance = localStorage.getItem('creditBalance');
        const storedRole = localStorage.getItem('userRole');

        if (storedToken) {
            setToken(storedToken);
            setIsAuthenticated(true);
        }

        if (storedCreditBalance) {
            setCreditBalance(Number(storedCreditBalance));
        }

        if (storedRole) {
            setRole(storedRole);
        }

        setPageLoading(false);
    }, []);


    const login = (data) => {
        setToken(data.token);
        setIsAuthenticated(true);
        setCreditBalance(data.creditBalance);
        setRole(data.role);

        localStorage.setItem('token', data.token);
        localStorage.setItem("name", data.name);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("userRole", data.role);
        localStorage.setItem("creditBalance", data.creditBalance);
    };


    const logout = () => {
        setToken(null);
        setIsAuthenticated(false);
        setCreditBalance(0);
        setRole(null);

        localStorage.removeItem('token');
        localStorage.removeItem('creditBalance');
        localStorage.removeItem('userRole');
        navigate('/login');
    };

    // Add the updateCreditBalance function
    const updateCreditBalance = (newBalance) => {
        setCreditBalance(newBalance);
        localStorage.setItem('creditBalance', newBalance);
    };

    const isAdmin = () => role === 'admin';
    return (
        <AuthContext.Provider value={{ isAuthenticated, token, isAdmin, role, creditBalance, updateCreditBalance, login, logout, pageLoading }}>
            {children}
        </AuthContext.Provider>
    );
};