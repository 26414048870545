import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contactUs } from "../services/contactService";

function ContactUs() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const displayErrorMessage = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsLoading(true);
      try {
        const res = await contactUs(values);
        console.log("Contact Form Submission: ", res);
        displayErrorMessage(res.message);
        resetForm();
        setTimeout(() => {
          navigate("/chat");
        }, 200);
      } catch (error) {
        displayErrorMessage(error.response.data.message);
        resetForm();
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="w-full min-h-full  flex justify-center items-center py-[40px] ">
      <ToastContainer />
      <div className="flex flex-col w-[90%]  xsm:w-[400px] md:w-[500px] gap-[20px] md:gap-[32px] py-[40px] md:py-[60px]  px-[40px] md:px-[60px] shadow-2xl bg-white rounded-[32px]">
        <div className="flex flex-col gap-[12px]">
          <h2 className="flex justify-center font-Popins text-[24px] md:text-[30px] leading-[32px] md:leading-[38px] font-[700] text-center text-[#010101]">
            Contact us
          </h2>
          <p className="flex justify-center font-Popins font-[400] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] text-center text-[#667085]">
            We'd love to hear from you. Please fill out this form.
          </p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex w-full flex-col gap-[20px]"
        >
          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.name && formik.errors.name
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="name"
              name="name"
              type="text"
              placeholder="Full Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-[12px] flex ">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              {...formik.getFieldProps("email")}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div className="relative flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className={`resize-none flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.message && formik.errors.message
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="message"
              name="message"
              placeholder="Enter your message"
              {...formik.getFieldProps("message")}
            />

            {formik.touched.message && formik.errors.message ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.message}
              </div>
            ) : null}
          </div>

          <div className="flex w-full">
            <button
              type="submit"
              disabled={formik.isSubmitting || isLoading}
              className="flex w-full justify-center rounded-[8px] py-[10px] px-[18px] bg-[#E61F26] border-[1px] border-[#E61F26] font-Popins text-[16px] font-[600] leading-[24px] text-white"
            >
              {isLoading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
