import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/login";
import Signup from "./components/signup";
import ConversationPage from "./components/conversationPage";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./Routes/protectedRoute";
import AuthRoute from "./Routes/authRoute";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPassword from "./components/ResetPassword";
import ContactUs from "./components/ContactUs";
import Recharge from "./components/Recharge";
import SiteSettings from "./components/SiteSettings";

//Updated Code for version 1.7
function App() {
  const isTokenExpired = () => {
    const token = localStorage.getItem("token");
    if (!token) return true;

    const payload = JSON.parse(atob(token.split(".")[1]));
    const now = Date.now() / 1000;
    return now > payload.exp;
  };

  return (
    <Router>
      <AuthProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <div className="App h-[100vh] min-w-screen ">
            <Routes>
              <Route path="/" element={<AuthRoute element={<Login />} />} />
              <Route
                path="/signup"
                element={<AuthRoute element={<Signup />} />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/site-settings"
                element={<ProtectedRoute element={<SiteSettings />} />}
              />
              <Route
                path="/recharge"
                element={<ProtectedRoute element={<Recharge />} />}
              />
              <Route
                path="/chat"
                element={<ProtectedRoute element={<ConversationPage />} />}
              />
              <Route
                path="*"
                element={
                  isTokenExpired() ? (
                    <Navigate to="/" />
                  ) : (
                    <Navigate to="/chat" />
                  )
                }
              />
              <Route path="/resetPassword" element={<ResetPassword />} />
            </Routes>
          </div>
        </GoogleOAuthProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
