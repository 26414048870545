import React from "react";
import { Button } from "antd";

const TabButton = ({ icon, label, active, onClick }) => (
  <Button
    className={`w-full p-1 text-left flex items-center ${
      active ? "bg-[#E4E7EC]" : "hover:bg-gray-100"
    } rounded`}
    type="text"
    onClick={onClick}
  >
    <img src={icon} alt={label} className="h-4" />
    <span className="ml-1 font-semibold text-gray-700 text-sm leading-5">
      {label}
    </span>
  </Button>
);

export default TabButton;