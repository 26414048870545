import axios from "axios";


const BASE_URL = 'https://api.protvguide.com/api/user';
// const BASE_URL = 'http://localhost:5005/api/user';


export const forgetPassword = async (content)=>{

  try {
    const response = await axios.post(`${BASE_URL}/forgot-password`, content);
    return response.data;
  } catch (error) {
    console.error('Error Posting User: ', error);
    throw error;
  }
  
  }

  export const resetPassword = async (content)=>{
    try {
      const response = await axios.post(`${BASE_URL}/resetPassword`, content);
      return response.data;
    } catch (error) {
      console.log("Error Resetting Password: ", error);
      throw error;
    }
  }

export const createnewUser = async (newUser) => {
    try {
      const response = await axios.post(`${BASE_URL}/signup`, newUser);
      return response.data;
    } catch (error) {
      console.error('Error Posting User: ', error);
      throw error;
    }
  };


  export const loginUser = async (userData) =>{
    try {
      const response = await axios.post(`${BASE_URL}/login`, userData);
      return response.data;
    } catch (error) {
      console.error('Error Posting User: ', error);
      throw error;
    }
  }