import React from "react";
import { Input, Select, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Discord from "../assets/discord.svg";
import Telegram from "../assets/telegram.svg";

const { Option } = Select;

const SPORTS = [
  "Spanish La Liga",
  "English Premier League",
  "Italian Serie A",
  "UEFA Champions League",
  "World Cup Qualifying CONMEBOL",
  "World Cup Qualifying UEFA",
  "World Cup Qualifying CONCACAF",
  "World Cup Qualifying OFC",
  "World Cup Qualifying AFC",
  "World Cup Qualifying CAF",
  "UEFA Nations League",
  "Mexico Liga MX Femenil",
  "French Ligue 1",
  "German Bundesliga",
  "NBA",
  "WNBA",
  "MLB",
  "NCAA Division I Basketball Mens",
  "NCAA Division 1",
  "NFL",
  "Formula 1",
  "Formula E",
  "NASCAR Cup Series",
  "All",
]

const AddChannelForm = ({
  step,
  channelId,
  setChannelId,
  channelName,
  setChannelName,
  sport,
  setSport,
  addChannel,
}) => (
  <div className="mt-4">
    <div className="grid grid-cols-2 gap-4">
      <div className="text-left">
        <Typography.Text>Channel ID</Typography.Text>
        <Input
          placeholder="Enter channel id"
          prefix={
            <img
              src={step === "discord" ? Discord : Telegram}
              alt="Platform"
              className="h-3"
            />
          }
          value={channelId}
          onChange={(e) => setChannelId(e.target.value)}
        />
      </div>
      <div className="text-left">
        <Typography.Text>Sports</Typography.Text>
        <Select
          className="w-full"
          placeholder="Select sport"
          value={sport}
          onChange={setSport}
        >
          {SPORTS.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
    </div>
    <div className="mt-4 text-left">
      <Typography.Text>Channel Name</Typography.Text>
      <Input
        placeholder="Enter channel name"
        value={channelName}
        onChange={(e) => setChannelName(e.target.value)}
      />
    </div>
    <div className="mt-4 text-left">
      <Button color="danger" variant="solid" icon={<PlusOutlined />} onClick={addChannel}>
        Add Channel
      </Button>
    </div>
  </div>
);

export default AddChannelForm;