import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { rechargeWallet } from "../services/walletService";
import { getSiteSettings } from "../services/generalService";
import { AuthContext } from "../context/authContext";

function Recharge() {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stripePublicKey, setStripePublicKey] = useState("");
  const [pricePerCredit, setPricePerCredit] = useState(0);
  const predefinedPlans = [100, 200, 500, 1000];
  const { updateCreditBalance } = useContext(AuthContext);

  const handlePlanSelect = (amount) => {
    setSelectedPlan(amount);
    setCustomAmount(""); // Reset custom input when a plan is clicked
    setErrorMessage("");
  };

  const handleCustomAmountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value <= 99) {
      setErrorMessage("Minimum limit is 100 credits.");
      setCustomAmount(e.target.value); // Update field even if invalid
      setSelectedPlan(null);
    } else if (value > 10000) {
      setErrorMessage("Maximum limit is 10,000 credits.");
      setCustomAmount(value);
      setSelectedPlan(null);
    } else {
      setErrorMessage("");
      setCustomAmount(value);
      setSelectedPlan(value);
    }
  };

  const handlePaymentSuccess = async (token) => {
    setIsProcessing(true);

    try {
      // Call the backend API to recharge the wallet
      const response = await rechargeWallet(token, selectedPlan, pricePerCredit, userId);

      // Mock payment processing or any other UI feedback
      setTimeout(() => {
        setIsProcessing(false);
        updateCreditBalance(response.user.creditBalance);
        toast.success(
          "Recharge successful! Credits have been added to your account."
        );

        navigate("/chat");
      }, 2000);
    } catch (error) {
      setIsProcessing(false);
      console.error("Payment failed:", error);
      toast.error("Failed to recharge wallet. Please try again.");
    }
  };

  const handleBack = () => {
    navigate("/chat");
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const result = await getSiteSettings();
        if (result?.data?.stripePublishableKey) {
          setStripePublicKey(result.data.stripePublishableKey);
          setPricePerCredit(result.data.pricePerCredit);
        } else {
          console.error("Stripe public key not found in site settings.");
        }
      } catch (error) {
        console.error("Failed to fetch site settings:", error);
      }
    };
    fetchSettings();
  }, []);

  return (
    <div className="w-full min-h-full  flex justify-center items-center py-[40px] ">
      <ToastContainer />
      <div className="flex flex-col w-[90%]  xsm:w-[400px] md:w-[800px] py-[40px]  px-[40px]">
        <button
          onClick={handleBack}
          className="absolute top-4 left-4 flex items-center hover:text-blue-500 transition duration-300 text-[#E61F26]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-5 h-5 mr-1 text-[#E61F26]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          Back
        </button>

        <h1 className="text-2xl font-semibold text-gray-700 mb-4">
          Recharge Credits
        </h1>
        <p className="text-gray-500 mb-6">
          Select a plan or enter a custom amount.
        </p>

        {/* Predefined Plans */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          {predefinedPlans.map((plan) => (
            <button
              key={plan}
              onClick={() => handlePlanSelect(plan)}
              className={`py-3 px-4 rounded-md text-center font-medium ${
                selectedPlan === plan
                  ? "bg-[#E61F26] text-white"
                  : "bg-gray-100 hover:bg-custom-red"
              }`}
            >
              {plan} Credits - ${Math.round(plan * pricePerCredit * 100) / 100}
            </button>
          ))}
        </div>

        {/* Custom Amount */}
        <div className="mb-4">
          <label
            htmlFor="customAmount"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Custom Amount
          </label>
          <input
            id="customAmount"
            type="number"
            min="1"
            placeholder="Enter custom amount"
            value={customAmount}
            onChange={handleCustomAmountChange}
            className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {errorMessage && (
            <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
          )}
        </div>

        {/* Order Summary */}
        {selectedPlan && (
          <div className="bg-custom-red border border-[#E61F26] rounded-md p-4 mb-6 transition-all duration-300">
            <h2 className="text-lg font-medium text-[#E61F26]">
              Order Summary
            </h2>
            <p className="text-gray-600">
              Credits: <span className="font-semibold">{selectedPlan}</span>
            </p>
            <p className="text-gray-600">
              Total Amount:{" "}
              <span className="font-semibold">
                ${Math.round(selectedPlan * pricePerCredit * 100) / 100}
              </span>
            </p>
          </div>
        )}

        {/* Stripe Checkout */}
        {selectedPlan && (
          <StripeCheckout
            stripeKey={stripePublicKey}
            token={handlePaymentSuccess}
            name="Recharge Credits"
            description={`${selectedPlan} Credits`}
            amount={selectedPlan * pricePerCredit * 100} // Convert to cents
            currency="USD"
            allowRememberMe
            billingAddress
            shippingAddress
          >
            <button
              disabled={isProcessing}
              className={`w-full py-3 px-6 rounded-md text-white font-medium transition-all duration-300 ${
                isProcessing
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-[#E61F26] hover:bg-custom-red hover:text-[#E61F26]"
              }`}
            >
              {isProcessing ? "Processing..." : "Recharge Now"}
            </button>
          </StripeCheckout>
        )}
      </div>
    </div>
  );
}

export default Recharge;
