import React, { useContext, useState } from "react";
import ToggleIcon from "../assets/toggle.svg";
import NewChatIcon from "../assets/newChat.svg";
import ChatIcon from "../assets/Chat.svg";
import LogoutIcon from "../assets/Group.svg";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../assets/delete.svg";
import { deleteSelectedChat } from "../services/chatService";
import FaqIcon from "../assets/wpf_faq.svg";
import RechargeIcon from "../assets/recharge.svg";
import ContactUsIcon from "../assets/contactUs.svg";
import SiteSettings from "../assets/siteSettings.svg";
import TimezoneSelect from "./TimezoneSelect";

function Sidebar({
  toggle,
  setToggle,
  chats,
  onSelectChat,
  setNewChat,
  selectedChatId,
  setSelectedChatId,
  initials,
  userName,
  handleNewChat,
  updateChats,
  setDisplayFAQ,
  displayFAQ,
  setTimeZone,
  timeZone,
}) {
  const { logout, creditBalance, isAdmin } = useContext(AuthContext);
  const [hoveredChatId, setHoveredChatId] = useState(null);

  const navigate = useNavigate();

  const handleSelect = (id) => {
    setNewChat(false);
    setSelectedChatId(id);
    onSelectChat(id);
    setDisplayFAQ(false);
    if (window.innerWidth < 768) {
      setToggle(!toggle);
    }
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleDelete = async (chatId) => {
    try {
      await deleteSelectedChat(chatId);
      setSelectedChatId(null);
      updateChats();
      if (window.innerWidth < 768) {
        setToggle(!toggle);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenFaqs = () => {
    setDisplayFAQ(true);
    setSelectedChatId(null);
  };
  const handleOpenContactUs = () => {
    navigate("/contact-us");
  };

  const handleOpenSiteSettings = () => {
    navigate("/site-settings");
  };

  const handleRecharge = () => {
    navigate("/recharge");
  };
  return (
    <div className="flex flex-col w-[300px] md:w-[290px] h-full bg-[#F9F9F9] p-[15px] md:p-[20px] shadow-lg overflow-y-auto">
      <div className="w-full flex items-center justify-between">
        <button
          onClick={handleToggle}
          className="p-[2px] hover:bg-[#e6e5e5] hover:rounded-md"
        >
          <img
            src={ToggleIcon}
            alt="Frame Icon"
            className="w-6 h-6 transform transition-transform duration-300 rotate-180"
          />
        </button>
        <button
          onClick={handleNewChat}
          className="p-[2px] hover:bg-[#e6e5e5] hover:rounded-md"
        >
          <img src={NewChatIcon} alt="Frame Icon" className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-grow flex flex-col w-full gap-2 mt-4">
        <span className="flex pl-[10px] text-[#8E8EA0] font-Popins text-[12px] font-[500] leading-[16px]">
          Recents
        </span>
        <div className="w-full flex flex-col-reverse gap-2">
          {chats.map((chat, index) => (
            <div
              key={index}
              onClick={() => handleSelect(chat._id)}
              onMouseEnter={() => setHoveredChatId(chat._id)}
              onMouseLeave={() => setHoveredChatId(null)}
              className={`w-full flex py-[8px] px-[12px] justify-between items-center hover:cursor-pointer hover:bg-[#e6e5e5] hover:rounded-[10px] ${
                selectedChatId === chat._id ? "bg-[#e6e5e5] rounded-[10px]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-[12px] flex-1 overflow-hidden">
                <img src={ChatIcon} alt="Chat Icon" className="w-4 h-4" />
                <span className="truncate overflow-hidden text-ellipsis whitespace-nowrap text-[#313133] font-Popins text-[12px] md:text-[14px] font-[400] leading-[20px]">
                  {chat.title}
                </span>
              </div>
              {(hoveredChatId === chat._id || selectedChatId === chat._id) && (
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering handleSelect when deleting
                    handleDelete(chat._id);
                  }}
                  className="flex justify-center items-center p-[4px] hover:bg-white hover:rounded-[3px]"
                >
                  <img src={DeleteIcon} alt="Delete Icon" className="w-4 h-4" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col justify-end">
        <TimezoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
        <button
          onClick={handleOpenFaqs}
          className={`w-full flex gap-[8px] items-center py-4 hover:bg-custom-red  hover:rounded-[10px] ${
            displayFAQ ? "bg-custom-red rounded-[10px]" : ""
          } `}
        >
          <img
            src={FaqIcon}
            alt="Faq Icon"
            className="pl-2 w-6 md:w-7 h-6 md:h-7 "
          />
          <span className="text-[#E61F26] font-Popins text-[14px] not-italic font-[500] leading-[18px] ">
            Faqs
          </span>
        </button>
        <button
          onClick={handleOpenContactUs}
          className={`w-full flex gap-[8px] items-center py-4 hover:bg-custom-red  hover:rounded-[10px] ${
            displayFAQ ? "bg-custom-red rounded-[10px]" : ""
          } `}
        >
          <img
            src={ContactUsIcon}
            alt="Faq Icon"
            className="pl-2 w-6 md:w-7 h-6 md:h-7 "
          />
          <span className="text-[#E61F26] font-Popins text-[14px] not-italic font-[500] leading-[18px] ">
            Contact Us
          </span>
        </button>
        {isAdmin() && (
          <button
            onClick={handleOpenSiteSettings}
            className={`w-full flex gap-[8px] items-center py-4 hover:bg-custom-red  hover:rounded-[10px] ${
              displayFAQ ? "bg-custom-red rounded-[10px]" : ""
            } `}
          >
            <img
              src={SiteSettings}
              alt="Setting Icon"
              className="pl-2 w-6 md:w-7 h-6 md:h-7 "
            />
            <span className="text-[#E61F26] font-Popins text-[14px] not-italic font-[500] leading-[18px] ">
              Settings
            </span>
          </button>
        )}
        <div className="w-full flex gap-[8px] items-center py-4 justify-between">
          <div className="w-10 h-10 flex justify-center items-center text-white font-[700] rounded-full bg-[#D9D9D9]">
            {initials}
          </div>
          <span className="text-[#13343B] font-Popins text-[12px] md:text-[14px] font-[500] leading-[18px]">
            {userName}
          </span>
          <span className="text-[#13343B] font-Popins text-[12px] md:text-[14px] font-[500] leading-[18px] flex items-center ml-auto">
            {isAdmin() ? "∞" : creditBalance} Credits
            <button
              onClick={handleRecharge}
              className="p-[2px] hover:bg-[#e6e5e5] hover:rounded-md ml-2"
            >
              <img src={RechargeIcon} alt="Frame Icon" className="w-4 h-4" />
            </button>
          </span>
        </div>
        <div
          onClick={handleLogout}
          className="w-full flex gap-[28px] items-center py-4 hover:cursor-pointer hover:bg-[#e6e5e5] hover:rounded-[10px]"
        >
          <img
            src={LogoutIcon}
            alt="Logout Icon"
            className="w-[20.751px] h-5"
          />
          <span className="text-[#13343B] font-Popins text-[12px] md:text-[14px] font-[500] leading-[18px]">
            Logout
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
