import axios from "axios";

const BASE_URL = "https://api.protvguide.com/api/contact";
//const BASE_URL = "http://localhost:5005/api/contact";

export const contactUs = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/post-contact`, payload);
    return response.data;
  } catch (error) {
    console.error("Error Posting Contact: ", error);
    throw error;
  }
};
