import React from "react";
import { Button, Typography } from "antd";
import AddChannelForm from "./AddChannelForm";
import ChannelList from "./ChannelList";
import { PlayCircleOutlined } from "@ant-design/icons";

const ChannelSettings = ({
  step,
  setStep,
  discordChannels,
  telegramChannels,
  channelId,
  setChannelId,
  channelName,
  setChannelName,
  sport,
  setSport,
  addChannel,
  removeChannel,
  setIsTutorialModalVisible,
}) => (
  <div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div className="flex gap-2">
      <Button
        className={`text-left flex items-center ${
          step === "discord" ? "bg-[#F2F4F7]" : "hover:bg-gray-100"
        } border-none rounded`}
        type="default"
        onClick={() => setStep("discord")}
      >
        Discord
      </Button>
      <Button
        className={`text-left flex items-center ${
          step === "telegram" ? "bg-[#F2F4F7]" : "hover:bg-gray-100"
        } border-none rounded`}
        type="default"
        onClick={() => setStep("telegram")}
      >
        Telegram
      </Button>
    </div>
    <Button
      icon={<PlayCircleOutlined style={{ color: "black" }} />}
      type="text"
      style={{ backgroundColor: "#F2F4F7", color: "black" }}
      onClick={() => setIsTutorialModalVisible(true)}
    >
      Tutorial
    </Button>    
    </div>
    <AddChannelForm
      step={step}
      channelId={channelId}
      setChannelId={setChannelId}
      channelName={channelName}
      setChannelName={setChannelName}
      sport={sport}
      setSport={setSport}
      addChannel={addChannel}
    />
    <Typography.Title level={5} className="mt-6 text-left">
      Added Channels
    </Typography.Title>
    <ChannelList
      channels={step === "discord" ? discordChannels : telegramChannels}
      step={step}
      removeChannel={removeChannel}
    />
  </div>  
);

export default ChannelSettings;