import axios from "axios";

const BASE_URL = 'https://api.protvguide.com/api/site';
//const BASE_URL = "http://localhost:5005/api/site";

export const saveSiteSettings = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/settings/update`, payload);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error Saving Site Settings: ", error);
    throw error;
  }
};

export const getSiteSettings = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/settings/get/`)
    return response;
  } catch (error) {
    console.error('Error fetching site settings: ', error);
    throw error;
  }
}
