import axios from "axios";

const BASE_URL = 'https://api.protvguide.com/api/user';
// const BASE_URL = 'http://localhost:5005/api/user';

// Forgot Password
export const forgetPassword = async (content) => {
  try {
    const response = await axios.post(`${BASE_URL}/forgot-password`, content);
    return response.data;
  } catch (error) {
    console.error('Error Posting User: ', error);
    throw error;
  }
};

// Reset Password
export const resetPassword = async (content) => {
  try {
    const response = await axios.post(`${BASE_URL}/resetPassword`, content);
    return response.data;
  } catch (error) {
    console.log("Error Resetting Password: ", error);
    throw error;
  }
};

// Create New User
export const createnewUser = async (newUser) => {
  try {
    const response = await axios.post(`${BASE_URL}/signup`, newUser);
    return response.data;
  } catch (error) {
    console.error('Error Posting User: ', error);
    throw error;
  }
};

// Login User
export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, userData);
    return response.data;
  } catch (error) {
    console.error('Error Posting User: ', error);
    throw error;
  }
};

// Update User
export const updateUser = async (userId, content) => {
  try {
    const response = await axios.put(`${BASE_URL}/users/${userId}`, content, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the token for authentication
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error Updating User: ', error);
    throw error;
  }
};

export const completeOnboarding = async (userId) => {
  try {
    const response = await axios.put(`${BASE_URL}/users/${userId}/complete-onboarding`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the token for authentication
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error Completing Onboarding: ', error);
    throw error;
  }
};