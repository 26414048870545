import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const [role, setRole] = useState(null);
  const [onboarding, setOnboarding] = useState(false);
  const [timezone, setTimezone] = useState('America/New_York');
  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedCreditBalance = localStorage.getItem('creditBalance');
    const storedRole = localStorage.getItem('userRole');
    const storedOnboarding = localStorage.getItem('onboarding');
    const storedTimezone = localStorage.getItem('timezone');
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');

    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }

    if (storedCreditBalance) {
      setCreditBalance(Number(storedCreditBalance));
    }

    if (storedRole) {
      setRole(storedRole);
    }

    if (storedOnboarding) {
      setOnboarding(storedOnboarding === 'true');
    }

    if (storedTimezone) {
      setTimezone(storedTimezone);
    }

    if (storedName) {
      setName(storedName);
    }

    if (storedEmail) {
      setEmail(storedEmail);
    }

    setPageLoading(false);
  }, []);

  const login = (data) => {
    setToken(data.token);
    setIsAuthenticated(true);
    setCreditBalance(data.creditBalance);
    setRole(data.role);
    setOnboarding(data.onboarding || false);
    setTimezone(data.timezone || 'America/New_York');
    setName(data.name || null);
    setEmail(data.email || null);

    localStorage.setItem('token', data.token);
    localStorage.setItem('name', data.name || '');
    localStorage.setItem('email', data.email || '');
    localStorage.setItem('userId', data.userId);
    localStorage.setItem('userRole', data.role);
    localStorage.setItem('creditBalance', data.creditBalance);
    localStorage.setItem('onboarding', data.onboarding || false);
    localStorage.setItem('timezone', data.timezone || 'America/New_York');
  };

  const logout = () => {
    setToken(null);
    setIsAuthenticated(false);
    setCreditBalance(0);
    setRole(null);
    setOnboarding(false);
    setTimezone('America/New_York');
    setName(null);
    setEmail(null);

    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('creditBalance');
    localStorage.removeItem('onboarding');
    localStorage.removeItem('timezone');
    navigate('/login');
  };

  const updateCreditBalance = (newBalance) => {
    setCreditBalance(newBalance);
    localStorage.setItem('creditBalance', newBalance);
  };

  const updateUserDetails = (newName, newTimezone) => {
    setName(newName);
    setTimezone(newTimezone);
    localStorage.setItem('name', newName);
    localStorage.setItem('timezone', newTimezone);
  };

  const isAdmin = () => role === 'admin';

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        isAdmin,
        role,
        creditBalance,
        onboarding,
        timezone,
        name,
        email,
        updateCreditBalance,
        updateUserDetails,
        login,
        logout,
        pageLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};