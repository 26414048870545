import React from "react";
import { Navigate } from "react-router-dom";

const isTokenExpired = () => {
  const token = localStorage.getItem("token");
  if (!token) return true;

  const payload = JSON.parse(atob(token.split(".")[1]));
  const now = Date.now() / 1000;
  return now > payload.exp;
};

function AuthRoute({ element }) {
  if (!isTokenExpired()) {
    return <Navigate to="/chat" replace />;
  }

  return element;
}

export default AuthRoute;
