import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveSiteSettings, getSiteSettings } from "../services/generalService";
import backButton from "../assets/backButton.svg";
import { AuthContext } from "../context/authContext";

function SiteSettings() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const { isAdmin, pageLoading } = useContext(AuthContext);

  const displayErrorMessage = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      freeQuestions: 0,
      creditPerWord: 0,
      pricePerCredit: 0,
      stripePublishableKey: "",
      stripePrivateKey: "",
    },
    validationSchema: Yup.object({
      freeQuestions: Yup.number()
        .typeError("Free credits must be a valid number")
        .min(0, "Free credits must be at least 0")
        .required("Free credits are required"),
      creditPerWord: Yup.number()
        .typeError("Price per word must be a valid number")
        .min(0, "Price per word must be at least 0")
        .required("Price per word is required"),
      pricePerCredit: Yup.number()
        .typeError("Price per credit must be a valid number")
        .min(0, "Price per credit must be at least 0")
        .required("Price per credit is required"),
      stripePublishableKey: Yup.string().required(
        "Stripe publishable key is required"
      ),
      stripePrivateKey: Yup.string().required("Stripe private key is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsLoading(true);
      try {
        const valuesWithUserId = { ...values, userId };
        const res = await saveSiteSettings(valuesWithUserId);
        console.log("Site Settings Form Submission: ", res);
        displayErrorMessage(res.message);
      } catch (error) {
        displayErrorMessage(error.response.data.message);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (pageLoading) return;

    if (!isAdmin()) {
      navigate("/chat");
    }

    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const res = await getSiteSettings();
        if (res && res.data) {
          formik.setValues(res.data);
        }
      } catch (error) {
        displayErrorMessage("Failed to load site settings");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [pageLoading,isAdmin,navigate]);

  const handleBack = () => {
    navigate("/chat");
  };

  return (
    <div className="w-full min-h-full flex justify-center items-center py-[40px] ">
      <button
        onClick={handleBack}
        className="absolute top-4 left-4 flex items-center hover:text-blue-500 transition duration-300 text-[#E61F26]"
      >
        <img
          src={backButton}
          alt="Back Icon"
          className="pl-2 w-6 md:w-7 h-6 md:h-7 "
        ></img>
        Back
      </button>
      <ToastContainer />
      <div className="flex flex-col w-[90%]  xsm:w-[400px] md:w-[900px] gap-[20px] md:gap-[32px] py-[40px] md:py-[60px]  px-[40px] md:px-[60px] bg-white">
        <div className="flex flex-col gap-[12px]">
          <h2 className="flex justify-center font-Popins text-[24px] md:text-[30px] leading-[32px] md:leading-[38px] font-[700] text-center text-[#010101]">
            Site settings
          </h2>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex w-full flex-col gap-[20px]"
        >
          <div className="flex flex-col gap-[6px]">
            <p className="text-lg text-left">Fee management</p>
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="freeQuestions"
            >
              No of free questions
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.freeQuestions && formik.errors.freeQuestions
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="freeQuestions"
              name="freeQuestions"
              type="number"
              placeholder="No of free qestions"
              {...formik.getFieldProps("freeQuestions")}
            />
            {formik.touched.freeQuestions && formik.errors.freeQuestions ? (
              <div className="text-red-500 text-[12px] flex ">
                {formik.errors.freeQuestions}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="creditPerWord"
            >
              No of credits per word
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.creditPerWord && formik.errors.creditPerWord
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="creditPerWord"
              name="creditPerWord"
              type="number"
              placeholder="Credits per word"
              {...formik.getFieldProps("creditPerWord")}
            />
            {formik.touched.creditPerWord && formik.errors.creditPerWord ? (
              <div className="text-red-500 text-[12px] flex ">
                {formik.errors.creditPerWord}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="pricePerCredit"
            >
              Price per credit
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.pricePerCredit && formik.errors.pricePerCredit
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="pricePerCredit"
              name="pricePerCredit"
              type="number"
              placeholder="Free credits"
              {...formik.getFieldProps("pricePerCredit")}
            />
            {formik.touched.pricePerCredit && formik.errors.pricePerCredit ? (
              <div className="text-red-500 text-[12px] flex ">
                {formik.errors.pricePerCredit}
              </div>
            ) : null}
          </div>

          <p className="text-lg text-left">Payment settings</p>
          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="stripePublishableKey"
            >
              Stripe publishable key
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.stripePublishableKey &&
                formik.errors.stripePublishableKey
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="stripePublishableKey"
              name="stripePublishableKey"
              type="text"
              placeholder="Enter stripe publishable key"
              {...formik.getFieldProps("stripePublishableKey")}
            />

            {formik.touched.stripePublishableKey &&
            formik.errors.stripePublishableKey ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.stripePublishableKey}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="stripePrivateKey"
            >
              Stripe private key
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.stripePrivateKey &&
                formik.errors.stripePrivateKey
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="stripePrivateKey"
              name="stripePrivateKey"
              type="password"
              placeholder="Enter stripe private key"
              {...formik.getFieldProps("stripePrivateKey")}
            />

            {formik.touched.stripePrivateKey &&
            formik.errors.stripePrivateKey ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.stripePrivateKey}
              </div>
            ) : null}
          </div>

          <div className="flex w-full">
            <button
              type="submit"
              disabled={formik.isSubmitting || isLoading}
              className="flex w-full justify-center rounded-[8px] py-[10px] px-[18px] bg-[#E61F26] border-[1px] border-[#E61F26] font-Popins text-[16px] font-[600] leading-[24px] text-white"
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SiteSettings;
