import axios from "axios";

const BASE_URL = 'https://api.protvguide.com/api/wallet';
// const BASE_URL = 'http://localhost:5005/api/wallet';

const rechargeWallet = async (token, selectedPlan, pricePerCredit, userId) => {
    try {
        const response = await axios.post(`${BASE_URL}/recharge`, {
            token: token.id,
            amount: selectedPlan * pricePerCredit, // Send amount
            credits: selectedPlan, // Send credits as well
            userId: userId
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        
        // Handle successful response
        console.log("Transaction successful:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error Recharging Wallet:", error);
        throw error;
    }
};

const getAllCredits = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/all-credits/${userId}`);
    return response;
  } catch (error) {
    console.error('Error fetching all credits: ', error);
    throw error;
  }
};

export { rechargeWallet, getAllCredits };