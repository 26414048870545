import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import Logo from "../assets/Logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./authContext";
import { useGoogleLogin } from "@react-oauth/google";
import { createnewUser, loginUser } from "../services/userService";
import SocialIcon from "../assets/Social icon.svg";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowPasswordIcon from "../assets/lightEye.svg"
import ForgetPasswordModal from "./forgetPasswordModal";



function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  

 

  //Information toast at every render
  const displayErrorMessage = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formRef.current.reportValidity()) {
      setIsLoading(true);
      const userData = {
        email,
        password,
        authProviderFlag: "local",
      };
      try {
        const response = await loginUser(userData);

        login(response);
        setEmail("");
        setPassword("");
        navigate("/chat");
      } catch (error) {
        displayErrorMessage(error.response.data.message)
      } finally {
        setIsLoading(false);
        setEmail("");
        setPassword("");
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { access_token } = tokenResponse;

      if (access_token) {
        let newUser;
        let userInfo;
        try {
          const response = await fetch(
            "https://www.googleapis.com/oauth2/v3/userinfo",
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );

          userInfo = await response.json();

          newUser =  {
            name: userInfo.name,
            email: userInfo.email,
            password: "",
            authProviderFlag: "google",
          }

          
          const userData = {
            email: userInfo.email,
            password: "", 
            authProviderFlag: "google",
          };


          const loginResponse = await loginUser(userData);
          login(loginResponse);
          navigate("/chat");
        } catch (error) {
          if(error.response.status===404){
            await createnewUser(newUser);
            const userData = {
              email: userInfo.email,
              password: "", 
              authProviderFlag: "google",
            };
  
  
            const loginResponse = await loginUser(userData);
            login(loginResponse);
            navigate("/chat");
            
          }
          else{
            displayErrorMessage(error.response.data.message);
          }
          
          setEmail("");
        setPassword("");
        }
      } else {
        console.log("Access token not found");
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token) {
      axios
        .get(`https://api.protvguide.com/api/user/verify-email?token=${token}`)
        .then(() => {
          displayErrorMessage("Email verified successfully! You can now log in.");
        })
        .catch(() => {
          displayErrorMessage("Verification failed. The token may be invalid or expired.");
        });
    }
  }, [location]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div className="w-full min-h-full flex justify-center items-center py-[40px] ">
      <ToastContainer />

      {isOpen && (
        <ForgetPasswordModal isOpen={isOpen} setIsOpen={setIsOpen} displayErrorMessage={displayErrorMessage} />
      ) }
      <div className="flex flex-col w-[90%]  xsm:w-[400px] md:w-[500px] gap-[20px] md:gap-[32px] py-[40px] md:py-[60px]  px-[40px] md:px-[60px] shadow-2xl bg-white rounded-[32px]">
        <div className="flex justify-center items-center">
          <img
            src={Logo}
            alt="App Logo"
            className="w-[69.6px] h-[60px] md:w-[89.6px] md:h-[80px] "
          />
        </div>

        <div className="flex flex-col gap-[12px]">
          <h2 className="flex justify-center font-Popins text-[24px] md:text-[30px] leading-[32px] md:leading-[38px] font-[700] text-center text-[#010101] ">
            Log into your account
          </h2>
          <p className="flex justify-center font-Popins font-[400] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] text-center text-[#667085] ">
            Welcome back! Please enter your details.
          </p>
        </div>

        

        <div className="flex flex-col gap-[24px]  ">
          <div className="flex w-full ">
            <form
              ref={formRef}
              onKeyDown={handleKeyPress}
              className="flex w-full flex-col gap-[20px]"
            >
              <div className="flex flex-col gap-[6px] ">
                <label
                  className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px] "
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px]  border-[1px] border-solid border-[#D0D5DD] "
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="relative flex flex-col gap-[6px]">
                <label
                  className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px] "
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px]  border-[1px] border-solid border-[#D0D5DD] "
                  id="password"
                  type={showPassword? "text" :"password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
            onClick={()=>setShowPassword(!showPassword)} 
            type="button"
            className="absolute top-10 right-2 " >
              <img src={ShowPasswordIcon} alt="Show Password" />
            </button>
              </div>
            </form>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-[8px] ">
              <input
                type="checkbox"
                id="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
              />
              <span className="flex items-center text-[#344054] font-Popins text-[12px] md:text-[14px] font-[500] leading-[18px] md:leading-[20px] ">
                Remember for 30 days
              </span>
            </div>
            <div className="flex">
              <button
                className="bg-transparent text-[#E61F26] font-Popins text-[12px] md:text-[14px] font-[600] leading-[18px] md:leading-[20px] "
                onClick={() => setIsOpen(!isOpen)}
              >
                Forgot password
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center w-full gap-[16px] ">
            <div className="flex w-full ">
              <button
                onClick={handleSubmit}
                className="flex w-full justify-center rounded-[8px] py-[10px] px-[18px] bg-[#E61F26] border-[1px] border-[#E61F26] font-Popins text-[16px] font-[600] leading-[24px] text-white"
                disabled={isLoading}
              >
                {isLoading ? "Signing in..." : "Sign in"}
              </button>
            </div>
            <div className="flex w-full rounded-[8px] border-[1px] border-[#D0D5DD]">
              <button
                onClick={() => handleGoogleLogin()}
                className="w-full flex items-center justify-center gap-[12px] py-[10px] px-[16px] rounded-[8px] hover:bg-[#D0D5DD] "
              >
                <img src={SocialIcon} alt="Google Social Icon" />
                <span className="text-[#344054] font-Popins text-[16px] font-[600] leading-[24px]">
                  Sign in with Google
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center gap-[4px]">
          <span className="text-[#667085] font-Popins text-[14px] font-[400] leading-[20px] ">
            Don’t have an account?
          </span>
          <button
            onClick={() => navigate("/signup")}
            className="flex text-[#E61F26] bg-transparent font-Popins text-[14px] font-[600] leading-[20px] "
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
