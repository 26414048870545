import { useFormik } from "formik";
import * as Yup from "yup";
import { XIcon } from "@heroicons/react/outline";
import { Box, Modal } from "@mui/material";
import { forgetPassword } from '../services/userService';

export default function ForgetPasswordModal({ isOpen, setIsOpen, displayErrorMessage }) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      if (values) {
        try {
          console.log(values);
          const response = await forgetPassword(values);
          displayErrorMessage(response.message);
        } catch (error) {
            displayErrorMessage(error.response.data.message);
        }
      }
      setIsOpen(false);
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? 400 : "90%",
    bgcolor: "#fff",
    border: "1px solid rgba(33, 33, 33, 0.10)",
    boxShadow: 24,
    p: 4,
    color: "#000",
    borderRadius: "10px",
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <span className="font-bold text-xl">Reset Password</span>
            <button type="button" onClick={() => setIsOpen(false)}>
              <XIcon className={`h-6 w-6`} aria-hidden="true" />
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <input
              id="email"
              type="email"
              {...formik.getFieldProps("email")}
              className="w-full p-2 border font-InterTight text-[14px] rounded text-black "
              placeholder="Enter your email"
              style={{
                borderColor:
                  formik.touched.email && formik.errors.email ? "red" : "gray",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: "red" }}>{formik.errors.email}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-[#E61F26] font-InterTight text-[16px] font-[600] rounded "
          >
            Send Verification Link
          </button>
        </form>
      </Box>
    </Modal>
  );
}
