import React from "react";
import { Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Discord from "../assets/discord.svg";
import Telegram from "../assets/telegram.svg";

const ChannelList = ({ channels, step, removeChannel }) => (
  <div className="flex flex-col gap-2">
    {channels.map((channel, index) => (
      <div
        key={index}
        className="bg-[#F9FAFB] border border-[#D0D5DD] p-2 rounded-md flex items-center justify-between"
      >
        <div className="flex items-center gap-2">
          <img
            src={step === "discord" ? Discord : Telegram}
            alt="Platform"
            className="h-3"
          />
          <div>
            <Typography.Text strong>{channel.channelName}</Typography.Text>
            <br />
            <Typography.Text style={{ fontSize: "12px", color: "gray" }}>
              #ID {channel.channelID}
            </Typography.Text>
          </div>
        </div>
        <Button
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => removeChannel(index)}
        />
      </div>
    ))}
  </div>
);

export default ChannelList;