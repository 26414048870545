import React from "react";
import { useNavigate } from "react-router-dom";

function CreditWarning() {
  const navigate = useNavigate();

  const handleOpenContactUs = () => {
    navigate("/recharge");
  };

  return (
    <div className="py-8">
      <h2 className="text-2xl font-semibold">Ops.. Your credits are over!</h2>
      <button onClick={handleOpenContactUs} className="bg-blue-500 text-white font-bold py-2 px-4 rounded my-6">Purchase Credits</button>
    </div>
  );
}

export default CreditWarning;