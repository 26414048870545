import React, { useEffect, useState, useRef, useContext } from "react";
import SendIcon from "../assets/Send.svg";
import Logo from "../assets/Logo.svg";
import PositiveIcon from "../assets/positive.svg";
import NegativeIcon from "../assets/negative.svg";
import CopyIcon from "../assets/copy.svg";
import CreditWarning from "../components/CreditWarning";
import { AuthContext } from "../context/authContext";
import {
  createNewChat,
  postNewMessage,
  getAIResponse,
  updateAIResponse,
} from "../services/chatService";

function LiveChat({
  chat,
  setIsModalOpen,
  updateMessages,
  updateChats,
  newChat,
  setNewChat,
  setSelectedChatId,
  initials,
  userName,
  setSelectedFeedback,
  toggle,
  chats,
  setSelectedChatMessages,
  selectedChatId,
  handleSetIntroFlag,
  timeZone,
}) {
  const [message, setMessage] = useState("");
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const chatEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [borderRadius, setBorderRadius] = useState("100px");
  const isSending = useRef(false);
  const [showCreditWarning, setShowCreditWarning] = useState(false);
  const { updateCreditBalance, timezone } = useContext(AuthContext);

  // Dynamically adjust the height of the textarea field
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height based on scroll height

      if (textareaRef.current.scrollHeight > 30) {
        setBorderRadius("30px");
      } else {
        setBorderRadius("100px");
      }
    }
    // eslint-disable-next-line
  }, [message]);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, [chat]);

  //Copy a particular AI Response Message
  const handleCopyMessage = (text, messageId) => {
    // Remove HTML and XML tags using regex
    const cleanText = text
      .replace(/<[^>]*>/g, "")
      // Remove extra whitespace and trim
      .replace(/\s+/g, " ")
      .trim();

    navigator.clipboard.writeText(cleanText).then(() => {
      setCopiedMessageId(messageId);
    });
  };

  //Open feedback Modal
  const handleFeedbackClick = (id, type) => {
    setSelectedFeedback({
      id,
      type,
    });
    setIsModalOpen(true);
  };

  //Set Default Messages for New Chat
  useEffect(() =>{
    if (!chat) {
      setDefaultMessages();
    }
    // eslint-disable-next-line
  }, [newChat]);

  useEffect(() => {
    if (chats.length === 0) {
      setDefaultMessages();
    }
    // eslint-disable-next-line
  }, [chats]);

  //Update AI response from typing to actual response from API
  const updateResponse = async (message) => {
    try {
      const postMessageResponse =await updateAIResponse(chat[0].chatId, message);
      updateMessages(chat[0].chatId);
      updateCreditBalance(postMessageResponse?.data?.user?.creditBalance);
    } catch (error) {}
  };

  //Set Deafault Messages
  const setDefaultMessages = async () => {
    const feedback = {
      type: "Positive",
      text: "",
    };

    const defaultMessages = [
      {
        role: "user",
        text: "Default Message",
        feedback: feedback,
      },
      {
        role: "assistant",
        text: `Hi <strong>${userName}</strong>!<br /><br />I am Samantha but you can call me Sam, 
        your personal sports buddy with a passion for all the things game-related.`,
        feedback: feedback,
      },
      {
        role: "assistant",
        text: `If you are wondering what to chat about, here are some sample questions to get us started:<br/><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;<i>1: What's the latest sports news?<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;2: Can you give me updates on my favorite team?<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;3: What are the upcoming game schedules?</i><br/><br/>`,
        feedback: feedback,
      },
      {
        role: "assistant",
        text: `Feel free to ask me anything sports-related, or just drop by to say Hi! Looking forwards to our chats!`,
        feedback: feedback,
      },
    ];

    const newChat = {
      title: "New Chat",
      userId,
    };

    try {
      const response = await createNewChat(newChat);
      for (let message of defaultMessages) {
        const newMessage = {
          chatId: response.chatId,
          role: message.role,
          text: message.text,
          feedback: message.feedback,
          userId: userId,
        };
        try {
          await postNewMessage(response.chatId, newMessage);
          updateMessages(response.chatId);
        } catch (error) {
          console.log("Error Posting Message: ", error);
        }
      }
      updateChats();
      // updateMessages(response.chatId);
      setSelectedChatId(response.chatId);
      setNewChat(!newChat);
    } catch (error) {
      console.log("Error Posting Chat: ", error);
    }

    return "Default Messages Added";
  };

  //Post Message in database
  const postMessage = async (message) => {
    try {
      await postNewMessage(chat[0].chatId, message);
      updateChats();
      updateMessages(chat[0].chatId);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { errorCode, message } = error.response.data;

        // Handle specific error codes
        switch (errorCode) {
          case "INSUFFICIENT_CREDITS":
            console.log("Error: Insufficient credits");
            setShowCreditWarning(true);
            scrollToBottom();
            break;

          default:
            console.error("Unexpected error:", message);
        }
      }
      //console.log("dgdu", error); return false;
      console.log("Error Posting Message: ", error);
    }
    setMessage("");
  };

  //Handles te messages when user send any message
  const handleSendMessage = async () => {
    if (isSending.current || !message.trim() || !chat || chat?.length < 4) {
      isSending.current = false;
      return; // Block multiple sends
    }
    isSending.current = true; // Set the ref to true immediately

    setLoading(true);

    const feedback = {
      type: "Positive",
      text: "",
    };

    let newMessage = {
      chatId: chat[0].chatId,
      role: "user",
      text: message,
      feedback: feedback,
      userId: userId,
    };
    // chat.push(newMessage);

    try {
      // Post the user's message
      // setSelectedChatMessages(chat);
      await postMessage(newMessage);

      // Post a typing indicator message from the assistant
      newMessage = {
        chatId: chat[0].chatId,
        role: "assistant",
        text: "Typing...",
        feedback: feedback,
        userId: userId,
      };
      // chat.push(newMessage);
      // setSelectedChatMessages(chat);
      await postMessage(newMessage);
      const payload = {
        query: [
          ...chat
            .map((chatMessage, index) => ({
              role: chatMessage.role,
              content: index === 0 ? "hi" : chatMessage.text, // Update first message content
            }))
            .filter((_, index) => index !== 2 && index !== 3),
          {
            role: "user",
            content: message,
          },
        ],
        timezone: timezone,
      };

      let responseAi;
      try {
        // Get AI response
        responseAi = await getAIResponse(payload);
      } catch (error) {
        console.error("Error Fetching Response From AI Bot: ", error);
      }

      // Update the assistant's message with the actual response from the AI
      newMessage = {
        chatId: chat[0].chatId,
        role: "assistant",
        text: responseAi ? responseAi.response : "Sorry, something went wrong.",
        feedback: feedback,
        userId: userId,
      };
      // replaceTypingMessage(newMessage);
      await updateResponse(newMessage);
    } catch (error) {
      console.error("Error handling message send process: ", error);
    } finally {
      setLoading(false); // Reset loading for UI feedback
      isSending.current = false; // Allow further sends
    }
  };

  return (
    <div
      className={`w-full h-screen flex flex-col relative pb-[10px]
    `}
    >
      {/* Chat content */}
      <div
        className={`flex-grow  overflow-y-auto py-4 flex flex-col gap-2
        ${toggle ? "" : "my-[60px]  md:mt-0"}
        `}
      >
        {chat && chat.length > 0
          ? chat
              .filter(
                (chatMessage, index) => chatMessage.text !== "Default Message"
              )
              .map((chatMessage, index) => (
                <div
                  key={chatMessage._id}
                  className={`flex py-[24px] p-[20px] md:pl-[30px] md:pr-[30px] lg:px-[50px] justify-between ${
                    chatMessage.role === "user"
                      ? "bg-transparent"
                      : "bg-[#F7F7F8]"
                  }`}
                >
                  <div className="flex">
                    <div
                      className={`flex rounded-[100px] w-[24px] h-[24px] md:w-[36px] md:h-[36px] justify-center text-white text-[12px] md:text-[16px] font-[700] font-Popins ${
                        chatMessage.role === "user" ? "bg-[#ACACBE]" : ""
                      }`}
                    >
                      {chatMessage.role === "user" ? (
                        <span className="flex  justify-center items-center">
                          {initials}
                        </span>
                      ) : (
                        <img
                          src={Logo}
                          alt="Logo"
                          style={{ maxWidth: "none" }}
                          className=""
                        />
                      )}
                    </div>
                    <div
                      className={`flex text-left font-Popins px-2 mx-2 rounded font-[400] ${
                        chatMessage.role === "user"
                          ? "text-[#666] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px]"
                          : "text-[#374151] text-[14px] md:text-[15.875px] leading-[24px] md:leading-[28px]"
                      }`}
                    >
                      {chatMessage.role === "user" ? (
                        <div className="font-Popins">{chatMessage.text}</div>
                      ) : (
                        <div className="font-Popins">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: chatMessage.text,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-end">
                    {chatMessage.role !== "user" ? (
                      <div className="flex gap-2 md:gap-4">
                        {copiedMessageId === chatMessage._id ? (
                          <p className="text-[10px] text-[#666] font-Popins">
                            copied
                          </p>
                        ) : (
                          <button
                            onClick={() =>
                              handleCopyMessage(
                                chatMessage.text,
                                chatMessage._id
                              )
                            }
                            className="p-1 hover:bg-[#e6e5e5] rounded-md"
                          >
                            <img
                              src={CopyIcon}
                              alt="Copy Icon"
                              style={{ maxWidth: "none" }}
                              className="w-3 h-3 md:w-4 md:h-4"
                            />
                          </button>
                        )}
                        <button
                          onClick={() =>
                            handleFeedbackClick(chatMessage._id, "Positive")
                          }
                          className="p-1 hover:bg-[#e6e5e5] rounded-md"
                        >
                          <img
                            src={PositiveIcon}
                            alt="Positive Icon"
                            style={{ maxWidth: "none" }}
                            className="w-3 h-3 md:w-4 md:h-4"
                          />
                        </button>
                        <button
                          onClick={() =>
                            handleFeedbackClick(chatMessage._id, "Negative")
                          }
                          className="p-1 hover:bg-[#e6e5e5] rounded-md"
                        >
                          <img
                            src={NegativeIcon}
                            alt="Negative Icon"
                            style={{ maxWidth: "none" }}
                            className="w-3 h-3 md:w-4 md:h-4"
                          />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))
          : ""}
        <div className="important-notice container h-full">
          {showCreditWarning && <CreditWarning />}
        </div>
        <div ref={chatEndRef} />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!loading) {
            handleSendMessage();
          }
        }}
        className="flex items-center py-[0.5rem] px-[5%] w-[100%] sm:px-[8%] md:px-[10%] mx-auto bg-transparent sm:absolute sm:mx-auto fixed bottom-0"
      >
        <div className="relative w-full bg-transparent">
          <textarea
            ref={textareaRef}
            className="w-full bg-[#F9F9F9] border border-[#EBEBFD] text-[#9393A4] font-Popins text-[16px] font-[400] leading-[20px] ps-5 p-2.5 md:py-4 pr-[50px] pl-[30px] overflow-y-auto resize-none"
            placeholder="Ask a question"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent adding a new line
                if (!loading) {
                  handleSendMessage(); // Submit the form
                }
              }
            }}
            required
            rows={1}
            style={{
              overflowY: "auto",
              transition: "height 0.2s ease, border-radius 0.2s ease",
              maxHeight: "150px",
              borderRadius: borderRadius,
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          />
          <style>
            {`
            /* Hide scrollbar for Chrome, Safari, and Opera */
            textarea::-webkit-scrollbar {
              display: none;
            }
            
            /* Media query for mobile screens */
            @media (max-width: 768px) {
              textarea {
                max-height: 100px; /* Set max-height to 100px for mobile screens */
              }
            }
          `}
          </style>
          <button
            type="submit"
            className="absolute inset-y-0 top-[-5px] end-0 flex items-center pe-3"
            disabled={loading}
          >
            <img src={SendIcon} className="w-6 h-6 md:w-8 md:h-8" alt="Send" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default LiveChat;
