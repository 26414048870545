import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/Logo.svg";
import ShowPasswordIcon from "../assets/lightEye.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../services/userService";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const [token, setToken] = useState("");
//   const { t } = useTranslation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setToken(query.get("token"));
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const setMessage = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[A-Z]/, "Password must contain at least 1 uppercase character")
        .matches(/[a-z]/, "Password must contain at least 1 lowercase character")
        .matches(/[0-9]/, "Password must contain at least 1 number")
        .matches(/[\W_]/, "Password must contain at least 1 special character")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Password must match"
        )
        .required("Confirm password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setLoading(true);
        const content = {
          token,
          password: values.newPassword,
        };
        if (token) {
          const res = await resetPassword(content);
          setMessage(res.message);
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
        resetForm();
      } catch (error) {
        setMessage(error.response.data.error);
        resetForm();
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      className={`relative z-0 w-full  min-h-[100vh] flex justify-center items-center px-[20px] py-[100px] `}
    >
      <div className="absolute z-20 top-3 left-5 md:left-[100px] ">
        <button onClick={() => navigate("/")}>
          <img
            src={Logo}
            alt="App Logo"
            className="w-[120px] h-[63px]"
          />
        </button>
      </div>
      <ToastContainer />
      <div
        style={{
          border: "2px solid rgba(84, 84, 84, 0.10)",
          background: "rgba(255, 255, 255, 0.50)",
          backdropFilter: "blur(25px)",
        }}
        className={`relative z-20  flex flex-col justify-center w-full max-w-[400px] sm:max-w-[544px] items-center rounded-[20px] p-[32px]`}
      >
        <div className="flex flex-col w-full  gap-[20px] md:gap-[32px]    sm:p-[60px]">
          <div className="flex flex-col max-w-[360px]  gap-[12px]">
            <span className="font-ClashGrotesk text-[24px] md:text-[36px] leading-[32px] md:leading-[48px] font-[600] tracking-[-0.6px] text-center">
                ResetPassword
            </span>
          </div>

          <div className="flex flex-col gap-[24px]  ">
            <div className="flex w-full ">
              <form
                onSubmit={formik.handleSubmit}
                className="flex w-full flex-col gap-[20px]"
              >
                <div className=" flex flex-col gap-[6px]">
                  <label
                    className="flex font-InterTight text-[12px] md:text-[14px] not-italic font-[500] leading-[20px] "
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      style={{
                        border:
                           "1px solid var(--Cognitive-Emperor, #545454)",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      }}
                      className={` flex w-full bg-transparent py-[10px] px-[14px] gap-[8px] rounded-[8px] font-InterTight not-italic text-[12px] md:text-[14px] font-[400] leading-[20px]
                    ${
                      formik.touched.newPassword && formik.errors.newPassword
                        ? "border-red-500"
                        : "border-[#D0D5DD]"
                    }
                    `}
                      id="newPassword"
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      {...formik.getFieldProps("newPassword")}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={`absolute  right-2 top-3  } `}
                    >
                      <img
                        src={ShowPasswordIcon}
                        alt="Eye Icon"
                      />
                    </button>
                  </div>

                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-red-500 text-[12px] flex">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>

                <div className=" flex flex-col gap-[6px]">
                  <label
                    className="flex font-InterTight text-[12px] md:text-[14px] not-italic font-[500] leading-[20px] "
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      style={{
                        border:
                           "1px solid var(--Cognitive-Emperor, #545454)"
                            ,
                        boxShadow:
                           "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            ,
                      }}
                      className={` flex w-full bg-transparent py-[10px] px-[14px] gap-[8px] rounded-[8px] font-InterTight not-italic text-[12px] md:text-[14px] font-[400] leading-[20px] 
                    ${
                      formik.touched.password && formik.errors.password
                        ? "border-red-500"
                        : "border-[#D0D5DD]"
                    }
                    `}
                      id="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Your Password"
                      {...formik.getFieldProps("confirmPassword")}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className={`absolute  right-2 top-3  } `}
                    >
                      <img
                        src={ShowPasswordIcon}
                        alt="Eye Icon"
                      />
                    </button>
                  </div>

                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-red-500 text-[12px] flex">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col items-center w-full sm:mt-4 gap-[16px] ">
                  <div className="flex w-full ">
                    <button
                      type="submit"
                      className="flex bg-[#E61F26] w-full justify-center rounded-[8px] py-[12px] px-[20px]   font-InterTight text-[14px] md:text-[16px] font-[700] leading-[22px] text-white"
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{ color: "white" }}
                          size={25}
                          disableShrink
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;